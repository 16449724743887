// Variables
@import 'variables';

// Bootstrap
$theme-colors: (
    "primary": #2166b4,
    "warning": #F7F523,
    "danger": #da0701,
    "info": #bee2ff,
    //"success": #138524,
);
$border-success:  #8cbf7a;
@import '~bootstrap/scss/bootstrap';

// Font Awesome
$fa-font-display: swap;
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Modal Video
@import '~modal-video/scss/modal-video.scss';

.btn-purple {
    color: #fff;
    background-color: #9230d9;
    border-color: #9230d9;
}
.btn-purple:hover {
    color: #fff;
    background-color: #893aff;
    border-color: #9230d9;
}
.linkbox{
    position: relative;
}
.linkbox a{
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
}
.linkbox a:hover{
    opacity: 0.1;
    background-color: #000000;
}
.navbar-nav div.active {
    background-color: rgba(0, 0, 0, 0.05);
}
body{
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体",
  "Helvetica Neue",
  Arial,
  "Hiragino Kaku Gothic ProN",
  "Hiragino Sans",
  Meiryo,
  sans-serif;
}
a.anchor{
    display: block;
    padding-top: 30px;
    margin-top: -30px;
}
h2{
    @include media-breakpoint-down(xs) {
        font-size: 4.5vw;
        font-weight: bold;
    }
}
h3{
    @include media-breakpoint-down(xs) {
        font-size: 4vw;
        font-weight: bold;
    }
}
@import 'kitato';
@import 'products';
@import 'development';
@import 'contact';
